import { tv, type VariantProps } from 'tailwind-variants'

export const iconButtonVariants = tv(
  {
    base: 'flex items-center justify-center aspect-square border shrink-0 rounded-full p-2 cursor-pointer disabled:pointer-events-none',
    variants: {
      color: {
        primary: 'bg-black border-black/10 text-white hover:bg-black/85',
        secondary: 'bg-white text-black hover:bg-white/85',
        transparent: 'bg-transparent border-transparent'
      },
      size: {
        lg: 'w-[80px]',
        md: 'w-[44px]',
        sm: 'w-[30px]'
      },
      hasBorder: {
        true: 'border-1 border-black/15',
        false: ''
      }
    },
    defaultVariants: {
      color: 'primary',
      size: 'md',
      hover: true
    }
  },
  {
    twMerge: false
  }
)

export type Variants = VariantProps<typeof iconButtonVariants>
